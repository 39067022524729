/* Estilos básicos para timer, ajuste conforme necessário */
.timer {
  font-weight: bold;
  padding: 5px;
}

.timer-container {
  background-color: #fff;
  padding: 10px 15px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: inline-flex;
  align-items: center;
  gap: 10px;
  margin: 10px 0;
}

.timer-text {
  font-size: 14px;
  color: #333;
  margin: 0;
}

.timer-count {
  font-weight: bold;
  color: #e4022d;
}

.timer-icon {
  color: #e4022d;
  font-size: 16px;
}
