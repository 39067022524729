.star {
  font-size: 24px;
  color: #ffff;
}

.star.filled {
  color: gold;
}

.star-rating {
  display: flex;
}
