:root {
  --color-background: #fffdf7;
  --color-black: #212121;
  --color-text: #4f4f4f;
  --color-text-out: #90908e;
  --color-primary: #a8001c;
  --color-primary-out: #ff6f6f;
  --color-secondary: #ffcecc;
  --color-white: #ffffff;
  --color-red: #e74c3c;
  --color-green: #2ecc71;
  --color-separate: #cccccc;
  --color-input-out: #ececec;
}

html,
body {
  font-family: "PoppinsRegular", "Rubik", sans-serif;
  background-color: var(--color-background);
  color: var(--color-text);
  font-size: 16px;
}

b {
  font-family: "PoppinsMedium", "Rubik", sans-serif;
}

.hidden {
  display: none !important;
}

.pr-0 {
  padding-right: 0px !important;
}

.bg-top {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  height: 110px;
  background-color: var(--color-secondary);
}

.width-fix {
  width: calc(100% - 20px);
  max-width: 800px;
  margin: 0px auto;
}

.card {
  border-radius: 20px;
  padding: 15px 20px;
  font-size: 20px;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1) !important;
  border: none !important;
}

.container-img {
  width: 60px;
  height: 60px;
  /* background-image: url('../img/logo.png'); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin-right: 20px;
  border-radius: 20px;
}

.infos {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.infos h1 {
  font-size: 18px;
  margin-bottom: 5px;
  color: var(--color-black);
}

.infos-sub {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.status-open {
  font-size: 14px;
  margin-bottom: 0px;
  color: var(--color-green);
}

.status-closed {
  font-size: 14px;
  margin-bottom: 0px;
  color: var(--color-red);
}

.link {
  text-decoration: none !important;
  font-size: 14px;
  color: var(--color-primary) !important;
}

section.categoria {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 100;
  background: var(--color-background);
  color: var(--color-text);
  width: 100%;
  padding-top: 15px;
}

.container-menu {
  margin-bottom: 30px;
  overflow-x: auto;
  overflow-y: hidden;
  display: flex;
  padding-bottom: 5px;
  padding-top: 5px;
  padding-left: 10px;
}

.item-categoria {
  flex: none;
}

.btn-white {
  color: var(--color-black);
  background-color: var(--color-white);
  border-radius: 20px;
  padding: 15px 25px;
  font-size: 20px;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
}

.btn-white-select {
  color: var(--color-text-out);
  background-color: var(--color-primary);
  border-radius: 20px;
  padding: 15px 25px;
  font-size: 20px;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
}

.btn-yellow {
  border: none !important;
  background-color: var(--color-primary);
  color: var(--color-text-out);
  border-radius: 20px;
  padding: 15px 25px;
  font-size: 20px;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
}

.btn-sm {
  padding: 8px 13px;
  border-radius: 15px;
  font-size: 16px;
}

.btn-white:hover,
.btn-white.active {
  background-color: var(--color-primary);
}

.title-categoria {
  color: var(--color-black);
  margin-left: 5px;
}

.container-group .card {
  padding: 15px;
  cursor: pointer;
}

.container-img-produto {
  width: 100px;
  height: 100px;
  background-repeat: no-repeat;
  background-position: center;
  background-color: var(--color-secondary);
  border-radius: 15px;
  margin-right: 20px;
  flex: none;
}

.infos-produto {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.infos-produto .name {
  font-size: 18px;
  color: var(--color-black);
  margin-bottom: 0px;
}

.infos-produto .description {
  font-size: 14px;
  color: var(--color-text);
  margin-bottom: 5px;
}

.infos-produto .price {
  font-size: 16px;
  color: var(--color-primary);
  margin-bottom: 0px;
}

.mais-venvida {
  color: var(
    --color-primary
  ); /* Cor laranja (pode alterar para a cor que desejar) */
  font-weight: bold; /* Texto em negrito */
  font-size: 1rem;
  animation: pulsar 2.5s infinite; /* Animação de pulsar */
  margin-bottom: -5px;
  text-align: right;
  margin-top: -10px;
}

@keyframes pulsar {
  0% {
    transform: scale(1); /* Tamanho normal */
  }
  50% {
    transform: scale(1.1); /* Aumenta 10% */
  }
  100% {
    transform: scale(1); /* Volta ao tamanho normal */
  }
}

.menu-bottom {
  position: fixed;
  right: 0;
  left: 0;
  bottom: 10px;
  width: calc(100% - 20px);
  max-width: 800px;
  margin: 0 auto;
  border-radius: 50px;
  padding: 5px;
  font-size: 20px;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  background-color: var(--color-secondary);
  /* border: 1px solid #ffe28c; */
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.menu-bottom-item {
  text-decoration: none !important;
  color: var(--color-text-out) !important;
  font-size: 14px;
  flex: 1;
  text-align: center;
  padding: 12px 15px;
}

.menu-bottom-item.active {
  color: var(--color-text-out) !important;
  font-weight: bold;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  background-color: var(--color-primary);
  border-radius: 50px;
}

.badge-total-carrinho {
  width: 21px;
  height: 21px;
  background-color: var(--color-primary);
  color: var(--color-text-out) !important;
  border-radius: 50px;
  font-size: 12px;
  font-weight: bold;
  display: inline-block;
  position: relative;
  padding-top: 2px;
  margin-right: 2px;
}

.menu-bottom.disabled {
  justify-content: center;
  padding: 15px;
  font-size: 18px;
}

/* Detalhes do produto */

.bg-top.details {
  height: 410px;
}

.container-voltar {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none !important;
  font-size: 18px;
  color: var(--color-black) !important;
}

.container-imagem-produto {
  height: 240px;
  width: 100%;
  position: relative;
  overflow: hidden;
  border-radius: 20px;
  margin: 0 auto;
  margin-bottom: 10px;
}

.container-imagem-produto img {
  width: 100%;
  height: auto;
  object-fit: contain; /* Garante que a imagem seja completamente visível, mantendo a proporção */
  animation: moveUpDown 10s infinite alternate;
}

@keyframes moveUpDown {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(
      calc(-100% + 240px)
    ); /* Ajuste para garantir que a imagem inteira seja mostrada */
  }
}

.sub-title-categoria {
  color: var(--color-text);
  margin-left: 5px;
  font-size: 14px;
}

.card-opcionais {
  display: flex;
  flex-direction: row;
}

.infos-produto-opcional {
  flex: 1;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
}

.infos-produto-opcional .name,
.infos-produto-opcional .price {
  color: var(--color-black);
}

.opcionais .badge {
  float: right;
  background-color: var(--color-secondary);
  padding: 7px 12px;
  border-radius: 50px;
  color: var(--color-text-out);
  margin-top: 5px;
}

.checks {
  margin-left: 10px;
}

.container-check {
  display: block;
  position: relative;
  padding-left: 25px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
}

.container-check input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: -2px;
  left: 0;
  height: 25px;
  width: 25px;
  border-radius: 50px;
  background-color: var(--color-input-out);
}

.container-check:hover input ~ .checkmark {
  background-color: var(--color-text-out);
}

.container-check input:checked ~ .checkmark {
  background-color: var(--color-primary);
}

.checkmark::after {
  content: "";
  position: absolute;
  display: none;
}

.container-check input:checked ~ .checkmark::after {
  display: block;
}

.container-check .checkmark::after {
  left: 9px;
  top: 7px;
  width: 6px;
  height: 10px;
  border: solid var(--color-white);
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  border-width: 0 3px 3px 0;
}

.form-control {
  background-color: var(--color-white);
  color: var(--color-black) !important;
  border-radius: 20px;
  padding: 15px 25px;
  font-size: 16px;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1) !important;
  border: none !important;
  height: 50px;
}

textarea {
  height: 150px !important;
}

.menu-bottom.details {
  justify-content: space-between;
  padding: 8px 15px;
}

.add-carrinho {
  display: flex;
  text-align: center;
  height: 30px;
  justify-content: center;
  align-items: center;
}

.btn-menos,
.btn-mais {
  color: var(--color-black);
  padding: 8px 12px;
  border-radius: 10px;
  font-size: 14px;
  cursor: pointer;
  background: var(--color-primary-out);
}

.add-numero-itens {
  color: var(--color-black);
  padding: 2px 15px;
  font-size: 18px;
}

/* Sobre a empresa */

.bg-top.sobre {
  height: 180px;
}

.title-sobre {
  margin-bottom: 20px !important;
  margin-top: 5px;
  margin-right: 100px;
}

.sobre {
  font-size: 14px;
  color: var(--color-text);
}

.container-img-sobre {
  position: absolute;
  right: 20px;
  margin-top: -50px;
  width: 90px;
  height: 90px;
  background-repeat: no-repeat;
  background-position: center;
  background-color: var(--color-white);
  border-radius: 15px;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1) !important;
}

.normal-text {
  font-size: 14px;
  color: var(--color-text);
}

.normal-text b {
  color: var(--color-black);
}

.btn-full {
  font-weight: bold;
  position: fixed;
  right: 0;
  left: 0;
  bottom: 10px;
  width: calc(100% - 20px);
  max-width: 800px;
  margin: 0 auto;
  border-radius: 20px;
  padding: 15px 25px;
  font-size: 18px;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1) !important;
  border: 1px solid #ffe28c;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.btn-full-size {
  font-weight: bold;

  /* bottom: 10px; */
  width: 100%;
  max-width: 800px;
  /* margin: 0 auto; */
  border-radius: 20px;
  padding: 15px 25px;
  font-size: 18px;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1) !important;
  border: 1px solid #ffe28c;
  /* display: flex; */
  /* justify-content: space-between; */
  align-items: center;
  text-align: center;
}

.btn-full.voltar {
  justify-content: center;
  z-index: 9999;
}

/* Carrinho */

.bg-top.pedido {
  height: 180px;
}

.container-detalhes {
  display: flex;
}

.carrinho .detalhes-produto {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.carrinho .infos-produto {
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
}

.carrinho .infos-produto .name {
  font-size: 16px;
}

.carrinho .infos-produto .price {
  font-size: 14px;
  color: var(--color-black);
}

.carrinho .infos-produto .name-opcional,
.carrinho .infos-produto .price-opcional,
.carrinho .infos-produto .obs-opcional {
  font-size: 14px;
  color: var(--color-text);
  margin-left: 10px;
}

.carrinho .infos-produto .obs-opcional {
  margin-right: 75px;
}

.detalhes-produto-edit {
  font-size: 14px;
  padding-left: 10px;
  padding-right: 10px;
  color: var(--color-primary);
  cursor: pointer;
}

.carrinho .infos-produto .name-total {
  font-size: 18px;
  color: var(--color-black);
}

.carrinho .infos-produto .price-total {
  font-size: 18px;
  color: var(--color-primary);
}

.card-select {
  border: 2px dashed var(--color-primary) !important;
  cursor: pointer;
}

.color-primary {
  color: var(--color-primary);
}

.card-address {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.img-icon-details {
  width: 50px;
  height: 50px;
  background-color: var(--color-secondary);
  color: var(--color-black);
  font-size: 22px;
  border-radius: 11px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-address .infos {
  padding-left: 15px;
  padding-right: 15px;
  font-size: 14px;
}

.card-address .infos .name {
  color: var(--color-black);
}

.icon-edit {
  font-size: 16px;
  color: var(--color-primary);
}

/* Pedido */

.card-status-pedido {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 15px;
}

.card-status-pedido .infos {
  padding-left: 15px;
  padding-right: 15px;
  font-size: 14px;
}

.card-status-pedido .infos .name {
  color: var(--color-black);
}

.detalhes-produto {
  flex: 1;
  padding-left: 5px;
}

.detalhes-produto-acoes {
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-direction: column;
  font-size: 14px;
  padding: 5px 15px;
  border-right: 1px solid var(--color-separate);
  color: var(--color-black);
}

.detalhes-produto-acoes:last-child {
  border: none;
}

.detalhes-produto-acoes i {
  font-size: 18px;
}

.pedido .name-total {
  font-size: 14px;
  color: var(--color-black);
}

.pedido .price-total {
  font-size: 16px;
  color: var(--color-primary);
}

/* .card-status-pedido.active {
  background-color: var(--color-primary-out);
} */

.card-status-pedido.pending {
  opacity: 0.3;
}

.card-status-pedido.pending .img-icon-details {
  width: 30px;
  height: 30px;
  font-size: 14px;
  border-radius: 8px;
}

.card-status-pedido.cancelado .img-icon-details {
  background-color: var(--color-red);
  color: var(--color-white);
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Cor de fundo com transparência */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999; /* Valor alto para garantir que o modal fique acima de outros elementos */
}

/* Estilo para o conteúdo do modal */
.modal-content {
  background: #fff;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  overflow: auto;
  width: calc(100% - 20px);
  max-width: 800px;
  margin: 0 auto;
  overflow-y: visible;
}

/* Estilos para dispositivos móveis */
@media (max-width: 767px) {
  .modal-content {
    width: 100%;
    max-width: none;
    border-radius: 0; /* Remover borda arredondada */
    height: 100%;
  }
}

/* Estilo para fechar o modal (opcional) */
.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 20px;
  color: #333;
}

.rmsc {
  --rmsc-main: #4285f4;
  --rmsc-hover: #f1f3f5;
  --rmsc-selected: #e2e6ea;
  --rmsc-border: none;
  --rmsc-gray: #aaa;
  --rmsc-bg: #fff;
  --rmsc-p: 10px; /* Spacing */
  --rmsc-radius: 4px; /* Radius */
  --rmsc-h: 38px; /* Height */
}

.container-img-produto {
  position: relative; /* Certifique-se de que o container esteja posicionado */
}

.gift-icon {
  position: absolute;
  top: 0px; /* Ajuste conforme necessário */
  left: 0px; /* Ajuste conforme necessário */
  background-color: var(--color-primary);
  color: var(--color-text-out);
  /* color: white; */
  padding: 5px;
  border-radius: 50%;
  height: 36px;
  width: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.center-div {
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: #f0f0f0; */
}
.loader-container {
  position: relative;
  width: 150px;
  height: 150px;
}

.logo img {
  width: 80px;
  height: 80px;
  display: block;
  margin: 0 auto;
}

.spinner {
  position: absolute;
  top: -35px;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dot {
  width: 15px;
  height: 15px;
  background-color: #000000;
  border-radius: 50%;
  position: absolute;
  animation: spin 1.5s linear infinite;
}

.dot1 {
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  animation-delay: 0s;
}
.dot2 {
  top: 14%;
  left: 14%;
  animation-delay: 0.1s;
}
.dot3 {
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  animation-delay: 0.2s;
}
.dot4 {
  bottom: 14%;
  left: 14%;
  animation-delay: 0.3s;
}
.dot5 {
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  animation-delay: 0.4s;
}
.dot6 {
  bottom: 14%;
  right: 14%;
  animation-delay: 0.5s;
}
.dot7 {
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  animation-delay: 0.6s;
}
.dot8 {
  top: 14%;
  right: 14%;
  animation-delay: 0.7s;
}

@keyframes spin {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/* Estilos adicionais para o modal de dados */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  width: calc(100% - 30px);
  max-width: 400px;
  margin: 15px;
  animation: modalFadeIn 0.3s ease-out;
}

@keyframes modalFadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.form-label {
  color: var(--color-black);
  font-size: 14px;
  margin-bottom: 5px;
  display: block;
}

/* Estilização específica para o input com máscara */
.form-control {
  background-color: var(--color-white);
  color: var(--color-black);
  border: 1px solid var(--color-input-out);
  border-radius: 10px;
  padding: 12px 15px;
  font-size: 16px;
  width: 100%;
  transition: border-color 0.2s;
}

.form-control:focus {
  border-color: var(--color-primary);
  outline: none;
  box-shadow: 0 0 0 2px var(--color-secondary);
}

/* Ajustes para tela cheia em mobile */
@media (max-width: 767px) {
  .modal-content {
    width: 100%;
    height: 100%;
    margin: 0;
    max-width: none;
    border-radius: 0;
  }

  .modal-overlay {
    align-items: flex-start;
  }

  .card {
    height: 100%;
    border-radius: 0 !important;
    display: flex;
    /* flex-direction: column; */
  }

  /* Ajusta o botão para ficar no final do card */
  .card .btn-yellow {
    margin-top: auto;
    border-radius: 0;
  }
}
